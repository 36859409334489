@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';
/* @import 'views/nav.css';
@import 'form/slider.css'; */

.breadcrumb-item {
    list-style: none !important;
}

.flex-3 {
    flex: 1 30%; /* explanation below */
    margin: 5px;
    height: 100px;
    background-color: blue;
}

.alert {
    margin-bottom: 0 !important;
    padding: 0.75rem 1.25rem 0 1.25rem !important;
}

ol{
    list-style: none !important;
    margin: revert !important;
    padding: revert !important;
    list-style-type: decimal !important;
}

/*ul {*/
/*    list-style: unset !important;*/
/*    margin: revert !important;*/
/*    padding: revert !important;*/
/*}*/

/* Style the tab */
.tab {
    overflow: hidden;
    border: 1px solid #ccc;
    background-color: #f1f1f1;
}

/* Style the buttons that are used to open the tab content */
.tab button {
    background-color: inherit;
    float: left;
    border: none;
    outline: none;
    cursor: pointer;
    padding: 14px 16px;
    transition: 0.3s;
    width: 50%;;
}

/* Change background color of buttons on hover */
.tab button:hover {
    background-color: #ddd;
}

/* Create an active/current tablink class */
.tab button.active {
    background-color: #ccc;
}

/* Style the tab content */
.tabcontent {
    display: none;
    padding: 6px 12px;
    border-top: none;
}

.page-title h2 {
    text-align: center;
    color: #e41c25;
}

.danger {
    color: red;
}

.text-grey {
    color: #404041
}

.text-red {
    color: #e41c25;
}

.bg-black-project-x {
    background-color: #191818 !important;
}

.bg-red-project-x {
    background-color: #C0413F !important;
}

body {
    font-family: 'Source Sans Pro', system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
}

/* Replicanting bootstrap's styles with tailwindcss */
/* Custom global button styles */
.btn-primary {
    @apply bg-blue-600 hover:bg-blue-700 text-white py-1 px-2 rounded;
}

.btn-secondary {
    @apply bg-gray-500 hover:bg-gray-600 text-white py-1 px-2 rounded;
}

.btn-success {
    @apply bg-green-500 hover:bg-green-600 text-white py-1 px-2 rounded;
}

.btn-danger {
    @apply bg-red-500 hover:bg-red-600 text-white py-1 px-2 rounded;
}

.btn-info {
    @apply bg-blue-400 hover:bg-blue-200 text-gray-800 py-1 px-2 rounded;
}

.btn-dark {
    @apply bg-gray-700 hover:bg-gray-500 text-white py-1 px-2 rounded;
}

.btn-link {
    @apply text-blue-600 hover:text-blue-800 hover:underline disabled:text-gray-400 disabled:pointer-events-none rounded;
}

/* Custom global table styles */
.table {
    @apply min-w-full border-collapse border border-gray-300;
}

.table th {
    @apply border border-gray-300 px-4 py-2 text-left bg-gray-100;
}

.table td {
    @apply border border-gray-300 px-4 py-2;
}

.table-striped tr:nth-child(even) {
    @apply bg-gray-50;
}

.table-borderless {
    @apply min-w-full border-collapse border-none;
}

.table-borderless th {
    @apply border-none px-4 py-2 text-left bg-gray-100;
}

.table-borderless td {
    @apply border-none px-4 py-2;
}

.table-hover tr:hover {
    @apply bg-gray-100;
}

[x-cloak] {
    display: none !important;
}

.btn.disabled,
.btn[disabled],
fieldset[disabled] .btn {
    cursor: not-allowed;
}

.breadcrumb-active {
    border-bottom-width: 1px;
    border-bottom-color: #C0413F;
}